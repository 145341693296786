var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm thirdScenarioInfo",
          attrs: { title: "평가대상정보" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "공정",
                    name: "processName",
                  },
                  model: {
                    value: _vm.popupParam.processName,
                    callback: function ($$v) {
                      _vm.$set(_vm.popupParam, "processName", $$v)
                    },
                    expression: "popupParam.processName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "평가부서",
                    name: "assessDeptName",
                  },
                  model: {
                    value: _vm.popupParam.assessDeptName,
                    callback: function ($$v) {
                      _vm.$set(_vm.popupParam, "assessDeptName", $$v)
                    },
                    expression: "popupParam.assessDeptName",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-table", {
        ref: "table",
        staticClass: "q-mt-sm",
        attrs: {
          title: "시나리오",
          merge: _vm.grid.merge,
          columns: _vm.grid.columns,
          data: _vm.grid.data,
          editable: _vm.editable && !_vm.popupParam.disabled,
          gridHeightAuto: true,
          isTitle: true,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          hideBottom: true,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "customCol"
                  ? [
                      _c(_vm.imprComponent, {
                        tag: "component",
                        attrs: {
                          col: col,
                          props: props,
                          inputEditable:
                            _vm.editable && !_vm.popupParam.disabled,
                          isImmShow: false,
                          requestContentsCols: _vm.requestContentsCols,
                          tableKey: "ramThirdAssessScenarioId",
                          ibmTaskTypeCd: "ITT0000013",
                          ibmTaskUnderTypeCd: "ITTU000015",
                        },
                        on: { imprChange: _vm.imprChange },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "customArea",
                              fn: function ({ props }) {
                                return [
                                  _c(
                                    "q-form",
                                    { ref: "editForm" },
                                    [
                                      _c(
                                        "c-card",
                                        {
                                          staticClass:
                                            "cardClassDetailForm q-mb-sm",
                                          attrs: { title: "시나리오 정보" },
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "card-button" },
                                            [
                                              _c(
                                                "q-btn-group",
                                                { attrs: { outline: "" } },
                                                [
                                                  _vm.editable &&
                                                  !_vm.popupParam.disabled
                                                    ? _c("c-btn", {
                                                        attrs: {
                                                          isSubmit: _vm.isSave,
                                                          url: _vm.saveUrl,
                                                          param: [props.row],
                                                          mappingType: "PUT",
                                                          label: "저장",
                                                          icon: "save",
                                                        },
                                                        on: {
                                                          beforeAction:
                                                            function ($event) {
                                                              return _vm.saveScenario(
                                                                props.row
                                                              )
                                                            },
                                                          btnCallback:
                                                            _vm.saveScenarioCallback,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "template",
                                            { slot: "card-detail" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                                                },
                                                [
                                                  _c("c-textarea", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.popupParam.disabled,
                                                      editable: _vm.editable,
                                                      rows: 2,
                                                      label: "현재안전조치",
                                                      name: "currentSafetyMeasures",
                                                    },
                                                    model: {
                                                      value:
                                                        props.row
                                                          .currentSafetyMeasures,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.row,
                                                          "currentSafetyMeasures",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.row.currentSafetyMeasures",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                                                },
                                                [
                                                  _c("c-select", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.popupParam.disabled,
                                                      editable: _vm.editable,
                                                      comboItems:
                                                        _vm.riskStandardItems,
                                                      isChip: true,
                                                      itemText:
                                                        "ramRiskLevelName",
                                                      itemValue:
                                                        "ramRiskThirdStandardId",
                                                      type: "edit",
                                                      name: "ramRiskThirdStandardId",
                                                      label: "개선 전 판단결과",
                                                    },
                                                    on: {
                                                      datachange: (val) =>
                                                        _vm.datachange(props, {
                                                          name: "ramRiskThirdStandardId",
                                                        }),
                                                    },
                                                    model: {
                                                      value:
                                                        props.row
                                                          .ramRiskThirdStandardId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.row,
                                                          "ramRiskThirdStandardId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.row.ramRiskThirdStandardId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                                                },
                                                [
                                                  _c("c-select", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.popupParam.disabled,
                                                      editable: _vm.editable,
                                                      comboItems:
                                                        _vm.riskStandardItems,
                                                      isChip: true,
                                                      itemText:
                                                        "ramRiskLevelName",
                                                      itemValue:
                                                        "ramRiskThirdStandardId",
                                                      type: "edit",
                                                      name: "ramAfterRiskThirdStandardId",
                                                      label: "개선 후 판단결과",
                                                    },
                                                    on: {
                                                      datachange: (val) =>
                                                        _vm.datachange(props, {
                                                          name: "ramAfterRiskThirdStandardId",
                                                        }),
                                                    },
                                                    model: {
                                                      value:
                                                        props.row
                                                          .ramAfterRiskThirdStandardId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.row,
                                                          "ramAfterRiskThirdStandardId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.row.ramAfterRiskThirdStandardId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                                                },
                                                [
                                                  _c("c-datepicker", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.popupParam.disabled,
                                                      editable: _vm.editable,
                                                      default: "today",
                                                      type: "date",
                                                      name: "assessDate",
                                                      label: "평가일",
                                                    },
                                                    model: {
                                                      value:
                                                        props.row.assessDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.row,
                                                          "assessDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.row.assessDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                                                },
                                                [
                                                  _c("c-field", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.popupParam.disabled,
                                                      editable: _vm.editable,
                                                      data: props.row,
                                                      type: "dept_user",
                                                      name: "assessUserId",
                                                      label: "평가자",
                                                    },
                                                    model: {
                                                      value:
                                                        props.row.assessUserId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.row,
                                                          "assessUserId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.row.assessUserId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                                                },
                                                [
                                                  _c("c-textarea", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.popupParam.disabled,
                                                      editable: _vm.editable,
                                                      rows: 2,
                                                      label:
                                                        "추가 리스크관리 계획",
                                                      name: "improvementMeasures",
                                                    },
                                                    model: {
                                                      value:
                                                        props.row
                                                          .improvementMeasures,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.row,
                                                          "improvementMeasures",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.row.improvementMeasures",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ]
                  : col.name === "ramRiskLevelName"
                  ? [
                      _c(
                        "q-chip",
                        {
                          style: `background-color:${props.row.riskColor} !important;color:white;`,
                          attrs: {
                            "text-color": "white",
                            outline: "",
                            square: "",
                          },
                        },
                        [_vm._v(" " + _vm._s(props.row[col.name]) + " ")]
                      ),
                    ]
                  : col.name === "ramAfterRiskLevelName"
                  ? [
                      _c(
                        "q-chip",
                        {
                          style: `background-color:${props.row.riskColorAfter} !important;color:white;`,
                          attrs: {
                            "text-color": "white",
                            outline: "",
                            square: "",
                          },
                        },
                        [_vm._v(" " + _vm._s(props.row[col.name]) + " ")]
                      ),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }