<template>
  <div>
    <c-card title="평가대상정보" class="cardClassDetailForm thirdScenarioInfo">
      <template slot="card-detail">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :editable="editable"
            :disabled="true"
            label="공정"
            name="processName"
            v-model="popupParam.processName">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :editable="editable"
            :disabled="true"
            label="평가부서"
            name="assessDeptName"
            v-model="popupParam.assessDeptName">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      class="q-mt-sm"
      title="시나리오"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable&&!popupParam.disabled"
      :gridHeightAuto="true"
      :isTitle="true"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :hideBottom="true"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable&&!popupParam.disabled"
            :isImmShow="false"
            :requestContentsCols="requestContentsCols"
            tableKey="ramThirdAssessScenarioId"
            ibmTaskTypeCd="ITT0000013"
            ibmTaskUnderTypeCd="ITTU000015"
            @imprChange="imprChange"
          >
            <template v-slot:customArea="{ props }">
              <q-form ref="editForm">
                <c-card title="시나리오 정보" class="cardClassDetailForm q-mb-sm">
                  <template slot="card-button">
                    <q-btn-group outline >
                      <c-btn 
                        v-if="editable&&!popupParam.disabled" 
                        :isSubmit="isSave"
                        :url="saveUrl"
                        :param="[props.row]"
                        mappingType="PUT"
                        label="저장" 
                        icon="save"
                        @beforeAction="saveScenario(props.row)"
                        @btnCallback="saveScenarioCallback" />
                    </q-btn-group>
                  </template>
                  <template slot="card-detail">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <c-textarea
                        :disabled="popupParam.disabled"
                        :editable="editable"
                        :rows="2"
                        label="현재안전조치"
                        name="currentSafetyMeasures"
                        v-model="props.row.currentSafetyMeasures">
                      </c-textarea>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <c-select
                        :disabled="popupParam.disabled"
                        :editable="editable"
                        :comboItems="riskStandardItems"
                        :isChip="true"
                        itemText="ramRiskLevelName"
                        itemValue="ramRiskThirdStandardId"
                        type="edit"
                        name="ramRiskThirdStandardId"
                        label="개선 전 판단결과"
                        v-model="props.row.ramRiskThirdStandardId"
                        @datachange="val => datachange(props, { name: 'ramRiskThirdStandardId' })"
                      ></c-select>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <c-select
                        :disabled="popupParam.disabled"
                        :editable="editable"
                        :comboItems="riskStandardItems"
                        :isChip="true"
                        itemText="ramRiskLevelName"
                        itemValue="ramRiskThirdStandardId"
                        type="edit"
                        name="ramAfterRiskThirdStandardId"
                        label="개선 후 판단결과"
                        v-model="props.row.ramAfterRiskThirdStandardId"
                        @datachange="val => datachange(props, { name: 'ramAfterRiskThirdStandardId' })"
                      ></c-select>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <c-datepicker
                        :disabled="popupParam.disabled"
                        :editable="editable"
                        default="today"
                        type="date"
                        name="assessDate"
                        label="평가일"
                        v-model="props.row.assessDate"
                      ></c-datepicker>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <c-field
                        :disabled="popupParam.disabled"
                        :editable="editable"
                        :data="props.row"
                        type="dept_user"
                        name="assessUserId"
                        label="평가자"
                        v-model="props.row.assessUserId"
                      ></c-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <c-textarea
                        :disabled="popupParam.disabled"
                        :editable="editable"
                        :rows="2"
                        label="추가 리스크관리 계획"
                        name="improvementMeasures"
                        v-model="props.row.improvementMeasures">
                      </c-textarea>
                    </div>
                  </template>
                </c-card>
              </q-form>
            </template>
          </component>
        </template>
        <template v-else-if="col.name==='ramRiskLevelName'">
          <q-chip text-color="white" :style="`background-color:${props.row.riskColor} !important;color:white;`" outline square>
            {{props.row[col.name]}}
          </q-chip>
        </template>
        <template v-else-if="col.name==='ramAfterRiskLevelName'">
          <q-chip text-color="white" :style="`background-color:${props.row.riskColorAfter} !important;color:white;`" outline square>
            {{props.row[col.name]}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <div class="popup-bottom-bar">
      <!-- <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn 
            v-if="editable&&!popupParam.disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveScenario"
            @btnCallback="saveScenarioCallback" />
        </q-btn-group>
      </div> -->
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'thirdScenario',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',
        processCd: '',
        processName: '',
        assessDeptName: '',
        disabled: false,
        ramMatrixId: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [],
        columns: [
          {
            name: 'hazardDisasterTypeName',
            field: 'hazardDisasterTypeName',
            label: '재해유형',
            align: 'center',
            style: 'width:15%',
            sortable: false,
          },
          {
            name: 'riskHazardQuestionName',
            field: 'riskHazardQuestionName',
            label: '유해위험요인<br/>(위험한 상황과 사건)',
            align: 'left',
            style: 'width:50%',
            sortable: false,
          },
          {
            name: 'ramRiskLevelName',
            field: 'ramRiskLevelName',
            // 판단결과
            label: '개선 전<br/>판단결과',
            align: 'center',
            style: 'width:15%',
            sortable: false,
            type: 'custom'
          },
          {
            name: 'ramAfterRiskLevelName',
            field: 'ramAfterRiskLevelName',
            // 판단결과
            label: '개선 후<br/>판단결과',
            align: 'center',
            style: 'width:15%',
            sortable: false,
            type: 'custom'
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '',
            align: 'center',
            style: 'width:5%',
            type: 'custom',
            sortable: false
          },
        
        ],
        data: [],
      },
      riskStandardItems: [],
      editable: true,
      isSave: false,
      listUrl: '',
      listRiskStandardUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['riskHazardQuestionName'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/mobileTableImpr.vue'}`);
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.third.scenario.list.url
      this.listRiskStandardUrl = selectConfig.ram.riskThirdStandard.list.url
      this.saveUrl = transactionConfig.ram.third.scenario.save.url
      // code setting
      // list setting
      this.getMatrixRisk();
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.popupParam.ramRiskAssessmentPlanId,
        processCd: this.popupParam.processCd,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getMatrixRisk() {
      this.$http.url = this.listRiskStandardUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.popupParam.plantCd,
      }
      this.$http.request((_result) => {
        this.riskStandardItems = this.$_.map(_result.data, item => {
          return this.$_.extend(item, {
            attrVal1: item.riskColor
          })
        });
      },);
    },
    datachange(props, col) {
      if (col.name === 'ramRiskThirdStandardId') {
        let standard = this.$_.find(this.riskStandardItems, { ramRiskThirdStandardId: props.row.ramRiskThirdStandardId });
        this.$set(props.row, 'riskColor', standard.riskColor)
        this.$set(props.row, 'riskbookFlag', standard.riskRegisterFlag)
      } else if (col.name === 'ramAfterRiskThirdStandardId') {
        let standard = this.$_.find(this.riskStandardItems, { ramRiskThirdStandardId: props.row.ramAfterRiskThirdStandardId });
        this.$set(props.row, 'riskColorAfter', standard.riskColor)
      }
    },
    saveScenario(row) {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$set(row, 'chgUserId', this.$store.getters.user.userId);
              this.$set(row, 'editFlag', 'U');

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveScenarioCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
<style lang="sass">
.thirdScenarioInfo
  label
    padding: 0 !important
    padding-top: 2px !important
    .q-field__inner
      padding: 0 !important
      padding-left: 10px !important
</style>